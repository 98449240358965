@import "../../mixins.scss";

.contact-section {
  @include flex();
  position: relative;

  img[alt="arrow-icon"] {
    position: absolute;
    top: 60px;
    left: 0;
    width: 160px;
    height: auto;
  }

  .left {
    flex: 1;
    position: relative;
    .bg-pattern {
      position: absolute;
      top: -400px;
      left: 0;
      z-index: 999;
      background: url("../../assets/images/contact-section-bg.png") bottom
        center no-repeat;
      background-size: cover;
      height: 1050px;
      width: 100%;
      z-index: -1;
    }
    h3 {
      @include heading2();
      color: var(--primary-text);
      text-transform: uppercase;
      padding-left: 20%;
    }

    form {
      padding: 0 30px 0 20%;
      input {
        display: block;
        background: transparent;
        width: 100%;
        margin: 15px 0;
        padding: 10px 5px;
        border: none;
        border-bottom: 1px solid #d9d9d9;
        @include para();
        font-size: 1.2rem;
        color: var(--primary-text);
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: var(--primary-text);
        }
      }
      button {
        @include button($w: 120px);
        padding: 4px;
        span{
          padding: 3px 0;
        }
      }
    }
  }

  .right {
    flex: 1.2;
  }

  @media (max-width: 900px) {
    flex-direction: column;

    .left {
      width: 100%;
      padding-top: 270px;

      h3 {
        padding: 0 1%;
        text-align: center;
      }

      form {
        padding: 0 5%;
      }
    }
    .right {
      width: 90%;
      align-self: flex-end;
    }
  }
}
