@import "../../mixins.scss";

.testimonials {
  position: relative;
 

  .all-testimonials {
    padding: 0 10%;
    .testimonial-card {
      width: 75%;
      margin: 30px 0;
      padding-bottom: 30px;
      border-bottom: 1px solid #d9d9d9;
    }
  }

  @media (max-width:800px) {
    .t-section{
      flex-direction: column !important;
    }

    .all-testimonials{
      .testimonial-card{
        width: 100%;
      }
    }
  }
}
