@import "../../mixins.scss";

.info-nav {
  @include flex($jc: space-between);
  width: 100%;
  padding: 14px 10%;
  background-color: var(--primary-color);

  .address {
    flex: 1;
    @include flex($jc: flex-start);
    p {
      @include para($fw: 500);
      font-size: 2rem;
      color: var(--primary-text);
    }

    img {
      width: 35px;
      height: auto;
      margin-right: 8px;
    }
  }
  .call {
    flex: 1;
    @include flex($jc: flex-end);
    p {
      @include para($fw: 500);
      font-size: 2rem;
      color: var(--primary-text);
    }

    img {
      width: 35px;
      height: auto;
      margin-right: 8px;
    }
  }

  @media (max-width: 700px) {
    padding: 8px 2%;

    .address {
      flex: 1.2;
      p {
        font-size: 1.3rem !important;
      }

      img {
        width: 25px;
      }
    }
    .call {
      p {
        font-size: 1.3rem !important;
      }

      img {
        width: 25px;
      }
    }
  }
}
