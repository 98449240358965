@import "../../mixins.scss";

.legal {
    padding: 0 10%;
  h1 {
    @include heading2();
    color: var(--primary-text);
  }
  div {
    margin: 10px 0;
    h5 {
      @include para($fw: 600);
      color: var(--primary-text);
      margin-bottom: 3px
    }

    p {
      @include para();
      color: var(--primary-text);
      line-height: 28px;
    }
  }
}
