@import "../../mixins.scss";

.massage-insights {
    padding: 0 10%;
    position: relative;

    .m-section {
        padding: 0 10% 70px 10%;

        img[alt="leaf-icon"] {
            width: 200px;
            height: auto;
            position: absolute;
            top: -100px;
            right: 80px;
        }

        .right {

            .desc {
                span {
                    font-weight: 600;
                }
            }

            button {
                @include button($w: 260px);
            }
        }
    }

    &>h3 {
        @include heading2();
        color: var(--primary-text);
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .all-blogs {
        margin-bottom: 60px;
        width: 100%;
        @include flex();
        flex-wrap: wrap;

        .blog-card {
            min-width: 310px;
            max-width: 310px;
            margin: 15px 20px;

            img {
                height: auto;
                width: 310px;
            }

            .date {
                @include para($fw: 500);
                color: var(--primary-text);

                span {
                    color: var(--primary-color);
                    @include para($fw: 500);
                }
            }

            .title {
                @include heading2();
                color: var(--primary-text);
                line-height: 35px;
                font-size: 2rem;
                margin: 5px 0;
            }

            .short-desc {
                @include para();
                color: var(--primary-text);
            }

            a {
                background-color: #EF512F;
                margin: 15px 0 0 0;
                box-shadow: 0px 0px 13px #EF512F;
                height: 40px;
                width: 40px;
                border-radius: 100%;
                @include flex();
                transition: var(--advance);

                img {
                    height: auto;
                    width: 20px;
                }

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}
@media screen and (max-width: 767px){
    .massage-insights .all-blogs{
        margin-bottom: 0px;
    }
}