@import "../../mixins.scss";

.special-offers {
  padding: 0 10% 50px 10%;
  position: relative;

  .main-img {
    border-radius: 30px;
    border: none;
    height: 100%;
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }

  .title {
    @include heading2();
    color: var(--primary-text);
    margin-top: 15px;
    text-align: center
  }
  .second-title {
    @include heading3();
    color: var(--primary-text);
    margin-top: 15px;
    text-align: center
  }

  .content {
    @include flex;
    flex-direction: column;
    .heading {
      @include para();
      color: var(--primary-text);
      max-width: 60%;
    }
    ul {
      list-style-type: circle;
      margin: 12px 0 12px 0;
      // margin: 12px 0 12px 22px;
      .para1,
      .para2,
      .para3 {
        @include para();
        color: var(--primary-text);
        line-height: 30px;
        text-align: center
      }
    }

    & > button {
      @include button;
    }
  }

  @media (max-width: 700px) {
    padding: 0 3%;
    .info {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
