@import "../../mixins.scss";

.testimonial-card {

    .review {
      p {
        span {
          img[alt="quotation-open"] {
            width: 25px;
            height: auto;
            margin: 0 2px 4px -26px;
          }
          img[alt="quotation-close"] {
            width: 25px;
            height: auto;
            transform: scaleX(-1);
            margin: 0 0 -5px 8px;
          }
        }
        @include para();
        font-style: italic;
        color: var(--primary-text);
      }
    }

    .info {
      margin-top: 20px;
      @include flex($jc: flex-start);
      .left {
        flex: 0.1;
        img {
          width: 65px !important;
          height: auto;
        }
      }

      .right {
        justify-self: flex-start;
        padding-left: 15px;
        .name {
          @include para();
          color: var(--primary-text);
          font-weight: 600;
          font-size: 1.8rem;
        }
        .relation {
          @include para();
          color: var(--primary-text);
        }
        img[alt="rating"] {
          width: 100px;
          height: auto;
          margin-left: -3px;
        }
      }
    }
  }