.employment {
    .e-section {
        padding: 0 10%;

        img[alt="leaf-icon"] {
            width: 200px;
            height: auto;
            position: absolute;
            top: -100px;
            right: 80px;
        }

        .desc {
            span {
                font-weight: 600;
            }
        }
    }

    @media (max-width: 900px) {
        .e-section {
            margin: 0 0 0px 0;
        }
    }
}
