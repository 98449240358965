@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

// usage: animation: rotate linear 40s infinite backwards;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* google fonts */
@import url("https://fonts.googleapis.com/css2?family=Aclonica&family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");

/*  css variables */
:root {
  // Colors
  --primary-color: #f46150;

  --primary-text: #fff;
  --secondary-text: #000;

  // Transitions
  --basic: all 0.35s ease-in-out;
  --advance: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  //test-border
  --border: 1px solid black;
}

::placeholder {
  color: var(--secondary-text);
}

/* reset browser default */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 10px;
}

body {
  // background-color: #263238;
  background-color: #111;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
}

img {
  width: 100%;
}

//CUSTOM
.active {
  color: var(--primary-color) !important;
  font-weight: 500 !important;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: -15px;
    right: 0;
    left: 0;
    height: 10px;
    width: 10px;
    background-color: var(--primary-color);
    border: none;
    border-radius: 100%;
    margin: auto;
    transition: var(--advance);
  }

  @media (max-width: 1276px) {
    &:after {
      margin: unset;
      bottom: 0;
      top: 24px;
      left: 12px;
      right: unset;
    }
  }
}
