@import "../../mixins.scss";

.gift-certificates{
    padding: 0 10%;
    .heading{
        @include heading2();
        color: var(--primary-text);
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 15px;
    }
    .desc{
        @include para($fw: 00);
        color: var(--primary-text);
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 3px;
    }

    #loopz-card-purchase{
        
    }
}