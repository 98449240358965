// font-sizes
@mixin heading1() {
    font-weight: 400;
    font-size: 7rem;
    font-family: 'Aclonica', sans-serif;
  
    @media (max-width: 1130px) {
      font-size: 5rem;
    }
  
    @media (max-width: 700px) {
      font-size: 3rem;
    }
  }
  
  @mixin heading2() {
    font-weight: 400;
    font-size: 3.5rem;
    font-family: "Aclonica", sans-serif;
  
    @media (max-width: 700px) {
      font-size: 2.8rem;
    }
  }
  @mixin heading3() {
    font-weight: 400;
    font-size: 2.5rem;
    font-family: "Aclonica", sans-serif;
  
    @media (max-width: 700px) {
      font-size: 2.0rem;
    }
  }
  @mixin para($fw: 300) {
    font-weight: $fw;
    font-size: 1.6rem;
    font-family: "Jost", sans-serif;
  }
  
  @mixin label($fw: 500) {
    font-weight: $fw;
    font-size: 2.2rem;
    font-family: "Aclonica", sans-serif;
    text-transform: capitalize;
    @media (max-width: 700px) {
      font-size: 1.6rem;
    }
  }
  
  @mixin flex($jc: center, $ai: center) {
    display: flex;
    justify-content: $jc;
    align-items: $ai;
  }
  
  @mixin button($bgc: #F46150, $hbgc: #fff, $w: 160px, $c: #fff, $hc: #000) {
    @include flex;
    border: none;
    transition: all .3s;
    padding: 5px;
    background-color: $bgc;
    border-radius: 50px;
    a, span {
      padding: 8px 0;
      cursor: pointer;
      transition: all .3s;
      width: $w;
      border: 2px dashed var(--primary-text);
      border-radius: 50px;
      @include para($fw: 500);
      color: $c;
      text-transform: capitalize
    }
  
    &:hover {
      a, span {
        background-color: transparent;
        background-color: $hbgc;
        color: $hc;
        transform: translateX(20px);
      }
    }
  }
  