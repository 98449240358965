@import "../../mixins.scss";

.hero {
  position: relative;

  .content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    text-align: center;
    height: 100%;
    @include flex();
    flex-direction: column;
    .label {
      @include label();
      color: var(--primary-color);
    }
    h2 {
      @include heading1();
      color: var(--primary-text);
      text-shadow: 0px 6px 4px rgba(244, 97, 80, 0.58);
      line-height: 65px;
      letter-spacing: 3.5px;
      text-transform: uppercase;
      text-align: center;
      margin: 30px 0 20px 0;
      span {
        @include heading1();
        display: block;
        color: #666;
        text-shadow: 0px 6px 4px rgba(244, 97, 80, 0.58);
        line-height: 84px;
        text-transform: uppercase;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: var(--primary-text);
      }
    }
    .desc {
      @include para();
      color: var(--primary-text);
      max-width: 50%;
    }
    button {
      @include button($w: 260px);
      margin: 30px auto 30px auto;
    }
    // img {
    //   width: 250px;
    //   height: auto;
    // }

    .achievements {
      max-width: 800px;
      @include flex();
      a {
        // flex: 1;
        margin: 0 4px;
        img {
          width: 90px;
          // height: auto;
          height: 120px;
          object-fit: cover;
          aspect-ratio: 1/1;
        }

        &:nth-child(1) {
          display: none;

          img {
            // width: 130px;
            width: 200px;
            aspect-ratio: 1/0.7;
          }
        }
        &:nth-child(2) {
          img {
            // width: 130px;
            // width: 200px;
            width: 300px;
            height: auto;
            // aspect-ratio: 1/0.7;
            aspect-ratio: 1/0;
            border-radius: 6px;
          }
        }
        &:nth-child(3) {
          display: none;

          img {
            // width: 130px;
            width: 200px;
            aspect-ratio: 1/1;
          }
        }
        &:nth-child(4) {
          display: none;

          img {
            // width: 130px;
            width: 200px;
            aspect-ratio: 1/1;
          }
        }
      }
    }
  }

  img[alt="leaf-left"] {
    width: 180px;
    height: auto;
    position: absolute;
    top: 160px;
    left: 110px;
  }

  img[alt="leaf-right"] {
    width: 180px;
    height: auto;
    position: absolute;
    bottom: 150px;
    right: 260px;
  }

  img[alt="line"] {
    width: 360px;
    height: auto;
    position: absolute;
    top: 70px;
    right: 0;
  }

  img[alt="hang-icon"] {
    width: 130px;
    height: auto;
    position: absolute;
    bottom: -85px;
    right: 0;
    left: 0;
    margin: auto;
  }

  // .down {
  //   position: absolute;
  //   bottom: 35px;
  //   left: 0;
  //   right: 0;
  //   margin: auto;
  //   height: 150px;
  //   width: 1px;
  //   border: 1px dashed #fff;
  //   &:after {
  //     position: absolute;
  //     top: 150px;
  //     left: -60px;
  //     margin: auto;
  //     padding: 60px;
  //     content: "";
  //     border: 1px dashed #fff;
  //     border-radius: 100%;
  //     background: url(../../assets/images/down-arrow.svg) center center
  //       no-repeat;
  //   }
  // }

  @media (max-width: 980px) {
    .content {
      .label {
        @include label();
      }
      h4 {
        line-height: 40px;
        letter-spacing: 3.5px;
        margin: 20px 0 0 0;
        span {
          line-height: 84px;
        }
      }

      .desc {
        max-width: 50%;
      }
      button {
        @include button($w: 260px);
        margin: 20px auto;
      }

      img {
        width: 250px;
        height: auto;
      }
    }

    img[alt="leaf-left"] {
      width: 140px;
      top: 100px;
      left: 40px;
    }

    img[alt="leaf-right"] {
      width: 140px;
      bottom: 50px;
      right: 160px;
    }

    img[alt="line"] {
      display: none;
    }

    img[alt="hang-icon"] {
      width: 70px;
      bottom: -85px;
    }
  }

  @media (max-width: 900px) {
    .content {
      .achievements {
        display: none;
      }
    }
    img[alt="hang-icon"] {
      display: none;
    }
  }

  @media (max-width: 700px) {
    .content {
      .label {
        font-size: 1rem !important;
      }
      h4 {
        line-height: 0px;
        letter-spacing: 3.5px;
        font-size: 2rem;
        span {
          margin-top: 9px;
          line-height: 34px;
          font-size: 2rem;
          -webkit-text-stroke-width: 1px;
        }
      }

      .desc {
        max-width: 80%;
        font-size: 1.2rem;
      }
      button {
        display: none;
      }
    }

    img[alt="leaf-left"] {
      display: none;
    }

    img[alt="leaf-right"] {
      display: none;
    }
  }
}