@import "../../mixins.scss";

.blog-detail-page {
  padding: 0 10% 50px 10%;
  position: relative;

  .main-img{
    border-radius: 30px;
    border: none
  }

  .title {
    @include heading2();
    color: var(--primary-text);
    margin-top: 15px;
  }
  .content h3 {
    @include heading3();
    color: #fff;
    margin-top: 20px;
}
a.inner_A {
  font-size: 20px;
}
ul.ul_blogs_page {
  padding-left: 20px;
}
.ul_blogs_page li {
  font-size: 16px;
  padding: 10px 0px;
  list-style: circle;
}


  .info {
    margin: 30px 0;
    @include flex($jc: flex-start);
    .left {
      margin-right: 30px;
      .name {
        @include flex($jc: flex-start, $ai: flex-end);
        img {
          height: 30px;
          width: auto;
          margin-right: 7px;
        }
        p {
          @include para($fw:500);
          color: var(--primary-text);
          text-transform: capitalize;
        }
      }

      .date {
        margin: 7px 0 0 3px;
        @include para($fw: 500);
        color: var(--primary-text);
        span {
          color: var(--primary-color);
          @include para($fw: 500);
        }
      }
    }

    .right {
      margin-top: 6px;
      p {
        @include para($fw: 500);
        color: var(--primary-text);
        &:nth-child(2) {
          @include para();
          font-weight: 400 !important;
          margin-top: 7px;
          font-weight: 500;
        }
      }
    }
  }

  .content {
    .heading {
      @include para();
      color: var(--primary-text);
      max-width: 60%;
    }

    p {
      @include para();
      color: var(--primary-text);
      line-height: 30px;
    }
    .para2{
      margin: 8px 0;
    }
  }

  @media (max-width: 700px) {
    padding: 0 3%;
    .info {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
