@import "../../mixins.scss";

@keyframes fromTop {
  0% {
    opacity: 0;
    top: 80px;
  }

  100% {
    opacity: 1;
    top: 55px;
  }
}

.header {
  background: transparent;
  position: relative;
  width: 100%;
  padding: 15px 7%;
  position: absolute;
  z-index: 5;

  .main {
    width: 100%;
    @include flex($jc: space-between);

    .logo {
      width: 150px;
      height: auto;
      cursor: pointer;
    }

    .links1,
    .links2 {
      position: relative;
      .links1-ul,
      .links2-ul {
        @include flex();
        flex: 1;
        li {
          position: relative;
          transition: var(--advance);
          @include flex();
          a {
            font-weight: 400;
            font-size: 1.6rem;
            font-family: "Jost", sans-serif;
            color: var(--primary-text);
            margin: 0 10px;
          }

          &:after {
            content: "";
            position: absolute;
            bottom: -15px;
            right: 0;
            left: 0;
            height: 10px;
            width: 10px;
            background-color: var(--primary-color);
            border: none;
            border-radius: 100%;
            margin: auto;
            visibility: hidden;
            transition: var(--advance);
          }

          &:hover:after {
            visibility: visible;
          }

          .drop-icon {
            height: 25px;
            width: auto;
            transition: var(--advance);
            color: var(--primary-text);
            cursor: pointer;
            padding: 0;
            margin-left: -12px;
          }

          &:hover {
            .drop-icon {
              transform: rotate(180deg);
              color: var(--primary-color);
            }
          }
        }
        .adjust {
          &::after {
            bottom: -16px;
            left: 11.5px;
          }
        }
      }

      .submenu {
        position: absolute;
        top: 55px;
        left: 0;
        padding: 10px 0;
        z-index: 2;
        background-color: #fff;
        max-width: 550px;
        border-top: 4px solid var(--primary-color);
        border-radius: 5px;
        animation: fromTop ease 0.2s;

        .head {
          padding: 0 10px 10px 10px;

          h4 {
            font-family: "Jost", sans-serif;
            font-weight: 700;
            font-size: 20px;
            line-height: 22.99px;
            color: var(--secondary-text);
            margin-bottom: 5px;
          }

          p {
            @include para($fw: 400);
            color: #a5a5a5;
            line-height: 22px;
          }
        }

        hr {
          border-color: #6461612b;
          height: 2px;
        }

        .submenu-links {
          padding: 0px 10px 5px 10px;

          li {
            @include flex($jc: flex-start);
            transition: var(--basic);
            border-radius: 5px;

            a {
              @include para($fw: 400);
              padding: 7.5px 0;
              margin-left: 12px;
              width: 100%;
            }

            &:hover {
              background-color: #7e7e7e2f;
            }

            &:hover:after {
              visibility: hidden !important;
            }
          }

          .active {
            &:after {
              bottom: unset;
              right: unset;
              top: 14.3px;
              left: -15px;
              height: 10px;
              width: 10px;
            }
          }
        }
      }
    }

    .mobile-links {
      transition: var(--basic);
      display: none;
      position: absolute;
      top: 0px;
      left: 0;
      background-color: #222;
      width: 100%;
      z-index: 7;
      overflow: hidden;
      height: 0;
      padding-top: 0;

      li {
        padding: 27px 0;
        color: var(--primary-text);
        border-bottom: 1px solid #ffffff81;
        width: 100%;
        position: relative;
        @include flex($jc: flex-start, $ai: flex-start);
        &:nth-child(2){
          padding: 34px 0 16px 0;
        }
        a {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          font-size: 1.6rem;
          padding: 16px 30px;
          width: 100vw;
          font-weight: 400;
          font-family: "Jost", sans-serif;
          // border: 1px solid red;
        }


        .drop-icon {
          height: auto;
          width: 35px;
          transition: var(--advance);
          color: var(--primary-text);
          cursor: pointer;
          padding: 0;
          margin-left: 170px;
          margin-top: -23px;
          z-index: 999;
        }
      }
      .mobile-submenu {
        max-height: 0;
        transition: var(--basic);

        li {
          // padding: 12px 0 12px 0px;
          margin-left: 25px;
          color: var(--primary-text);
          border: none;
          a{
            // border-top: 1px solid #a5a5a573;

          }
          

          &:first-child {
            border: none;
            padding: 0;
            margin: 0;
          }

          &:nth-child(2) {
            margin-top: 10px;
            // border: none;
          }
        }
      }


      .show-submenu {
        max-height: 999px;
        transition: var(--basic);
      }
    }

    .show {
      transition: var(--basic);
      height: 750px;
      padding-top: 16px;
    }

    .nav-icons {
      z-index: 8;
      display: none;
      img {
        padding: 14px 12px;
        height: 40px;
        width: auto;
        background-color: var(--primary-color);
        display: block;
      }

      img[alt="cut"] {
        padding: 10px 12px;
        height: 40px;
      }
    }
  }

  @media (max-width: 1276px) {
    padding: 15px 7%;
    .main {
      .links1,
      .links2 {
        display: none;
      }
      .mobile-links {
        display: block;
      }

      .nav-icons {
        display: block;
      }
    }
  }

  @media (max-width: 1050px) {
    .main {
      .logo {
        width: 100px;
      }
    }
  }

  @media (max-width: 750px) {
    padding: 15px 5%;
    .main {
      .logo {
        width: 80px;
      }
    }
  }
}
